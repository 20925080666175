import { Component } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { LanguageService } from './services/language.service';
import { UserService } from './services/user.service';
import { User } from './models/user.model';
import { Info } from './models/http-response.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  language?: string;
  constructor(private translate: TranslateService, private languageService: LanguageService, private userService: UserService) {
    translate.setDefaultLang('de');
    translate.use('de');
    userService.currentUser$
      .pipe(
        switchMap((user) => {
          if (user) {
            this.language = user?.data.language;
            this.languageService.setLanguage(this.language);
            return translate.use(user?.data.language)
            .pipe(
              switchMap(() => this.onLanguageChange(user))
            );
          }
          return of(user);
        }),
      )
      .subscribe();

      const script = document.createElement('script');
      script.src = 'https://kit.fontawesome.com/bcb6f912e8.js';
      document.head.appendChild(script);

      const link = document.createElement('link');
      link.href = 'https://fonts.gstatic.com';
      link.rel = 'preconnect';
      document.head.append(link);
  }

  onLanguageChange(user: User): Observable<User | Info> {
    return this.translate.onLangChange.pipe(
      tap((langObject: LangChangeEvent) => {
        this.languageService.setLanguage(langObject.lang);
      }),
      switchMap((langObject: LangChangeEvent) => {
        const isLanguage = this.language !== langObject.lang;
        this.language = langObject.lang;
        return user && isLanguage ? this.userService.changeUserLanguage( langObject.lang) : of(user);
      }),
    );
  }
}
