import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Breed,
  Animal,
  MatchedAnimals,
  UploadImage,
  Diversity,
  Disease,
  Category,
  Trait,
} from '../models/animal.model';
import { pluck, shareReplay, map, tap } from 'rxjs/operators';
import { BackendData, BackendDataPaginated, Info } from '../models/http-response.model';
import { ISubmission } from '../models/event.model';
import { TranslateService } from '@ngx-translate/core';
import { IAnalyse } from '../models/analyse-status.model';

@Injectable({
  providedIn: 'root',
})
export class AnimalService {
  private _breedOptions!: Observable<Breed[]>;
  private _animals$: BehaviorSubject<any> = new BehaviorSubject({});
  private _config: any = {
    page: 1,
    limit: 50,
    search: '',
    active: 1,
    forLoggedInUser: 1,
    sort: '',
    direction: '',
    forInitialDashboardView: 1,
  };

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
  ) {}

  get breedOptions$(): Observable<Breed[]> {
    if (!this._breedOptions) {
      this._breedOptions = this.getBreeds().pipe(shareReplay(1));
    }
    return this._breedOptions;
  }

  get animals$(): Observable<any>{
    return this._animals$.asObservable();
  }

  onChangeLanguage(): void {
    this._breedOptions = null;
  }

  createAnimal(animal: any): Observable<Animal> {
    return this.http
      .post<BackendData<Animal>>(environment.animals, animal)
      .pipe(pluck('data'));
  }

  getAnimalsList(): Observable<Animal[]> {
    return this.http
      .get<BackendData<Animal[]>>(`${environment.animals}/type/dog/list`)
      .pipe(pluck('data'));
  }

  getAnimals(): Observable<Animal[]> {
    return this.http
      .get<BackendData<Animal[]>>(`${environment.animals}/type/dog`)
      .pipe(pluck('data'));
  }

  getAnimalById(id: string): Observable<Animal> {
    return this.http
      .get<BackendData<Animal>>(`${environment.animals}/${id}`)
      .pipe(pluck('data'));
  }

  setAnimalImg(formData: FormData): Observable<BackendData<UploadImage>> {
    return this.http.post<BackendData<UploadImage>>(
      `${environment.animals}/image`,
      formData
    );
  }

  deleteAnimal(animalId: string): Observable<Info> {
    return this.http.delete<Info>(`${environment.animals}/${animalId}`);
  }

  editAnimal(animalId: string, animal: any): Observable<BackendData<Animal>> {
    return this.http.put<BackendData<Animal>>(
      `${environment.animals}/${animalId}`,
      animal
    );
  }

  getAnimalSubmissions(animalId: string, config: {page: number, limit: number}): Observable<BackendData<ISubmission[]>> {
    let params = new HttpParams();
    Object.keys(config).forEach((key: string) => {
      if (config[key]) {
        params = params.append(key, config[key]);
      }
    });
    return this.http.get<BackendData<ISubmission[]>>(`${environment.submissions}/${animalId}`, {params});
  }

  checkTestkit(testkiNumber: string): Observable<Info> {
    return this.http.get<Info>(
      `${environment.animals}/testkit/check/${testkiNumber}`
    );
  }

  setTestkit(animalId: string, key: any): Observable<Info> {
    return this.http.put<Info>(
      `${environment.animals}/testkit/${animalId}`,
      key
    );
  }

  getBreeds(): Observable<Breed[]> {
    let params = new HttpParams();
    params = params.append('page', 1);
    params = params.append('limit', 10000);
    params = params.append('sort', 'name');
    params = params.append('direction', 'asc');
    params = params.append('language', this.translate.currentLang);
    return this.http
      .get<BackendData<Breed[]>>(`${environment.breeds}`, {params})
      .pipe(
        pluck('items'),
        map(data => {
          const unknownIndex = data.findIndex(breed => breed.name.en === 'Unknown');
          const unknown = data[unknownIndex];
          data.splice(unknownIndex, 1);
          data.unshift(unknown);
          const mixedBreedIndex = data.findIndex(breed => breed.name.en === 'Mixed Breed');
          const mixedBreed = data[mixedBreedIndex];
          data.splice(mixedBreedIndex, 1);
          data.unshift(mixedBreed);
          return data;
        })
      );
  }

  getBreedsSearchable(searchBy?: string): Observable<Breed[]> {
    let params = new HttpParams();
    params = params.append('page', 1);
    params = params.append('limit', 10000);
    params = params.append('sort', 'name');
    params = params.append('direction', 'asc');
    params = params.append('language', this.translate.currentLang)
    if (searchBy) {
      params = params.append('search', searchBy);
    }

    return this.http
      .get<BackendData<Breed[]>>(`${environment.breeds}`, {params})
      .pipe(pluck('items'));
  }

  getMatched(animalId: string): Observable<MatchedAnimals[]> {
    return this.http
      .get<BackendData<MatchedAnimals[]>>(`${environment.matching}/${animalId}`)
      .pipe(pluck('data'));
  }

  getCompared(firstAnimal: string, secondAnimal: string): Observable<any> {
    return this.http
      .get<any>(`${environment.matching}/${firstAnimal}/${secondAnimal}`)
      .pipe(pluck('data'));
  }

  getDiversity(id: string): Observable<Diversity> {
    return this.http
      .get<BackendData<Diversity>>(`${environment.diversity}/${id}`)
      .pipe(pluck('data'));
  }

  getBreedsWithId(uuid: string): Observable<Breed> {
    return this.http.get<BackendData<Breed>>(`${environment.breeds}/${uuid}`)
      .pipe(pluck('data'));
  }

  getAllDiseases(config: {[key: string]: any}): Observable<Disease[]> {
    let params = new HttpParams();
    Object.keys(config).forEach((key: string) => {
      if (config[key]) {
        params = params.append(key, config[key]);
      }
    });
    return this.http.get<BackendData<Disease[]>>(environment.diseases, {params})
    .pipe(pluck('items'));
  }

  getAllTraits(config: {[key: string]: any}): Observable<Trait[]> {
    let params = new HttpParams();
    Object.keys(config).forEach((key: string) => {
      if (config[key]) {
        params = params.append(key, config[key]);
      }
    });
    return this.http.get<BackendData<Trait[]>>(environment.traits, {params})
    .pipe(pluck('items'));
  }

  getAllAnimalsPaginated(config: {[key: string]: any}): Observable<BackendDataPaginated<Animal[]>> {  //paginated list of animals
    let params = new HttpParams();
    Object.keys(config).forEach((key: string) => {
      if (config[key]) {
        params = params.append(key, config[key]);
      }
    });
    return this.http.get<BackendDataPaginated<Animal[]>>(environment.animalsPaginated, {params});
  }

  getMyAnimalsDashboard(config: {[key: string]: any}): Observable<BackendDataPaginated<Animal[]>> {
    Object.keys(config).forEach((key: string) => {
      if (config[key]) {
        this._config[key] = config[key];
      } else {
        delete this._config[key]
      }
    });
    return this.getAllAnimalsPaginated(this._config)
      .pipe(
        tap((res) => this._animals$.next(res))
      )
  }

  getAllDiseaseCategories(): Observable<Category[]> {
    return this.http.get<BackendData<Category[]>>(environment.diseaseCategories)
    .pipe(pluck('data'));
  }

  getAllTraitCategories(): Observable<Category[]> {
    return this.http.get<BackendData<Category[]>>(environment.traitCategories)
    .pipe(pluck('data'));
  }

  getReportForAnimal(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob', observe: 'response'});
  }

  downloadAllBreedingClubReport(): Observable<any> {
    return this.http.get(environment.breedingClubReport, { responseType: 'blob', observe: 'response'});
  }

  getResultStatus(animalId: string): Observable<IAnalyse[]> {
    return this.http.get<BackendData<IAnalyse[]>>(`${environment.resultStatus}/${animalId}`)
    .pipe(pluck('data'));
  }
}
