import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AnimalService } from 'src/app/services/animal.service';
import { CheckDeviceService } from 'src/app/services/check-device.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() isAuthorized = true;
  showLanguageDropdown = false;

  get isMobile(): boolean {
    return this.checkDeviceService.isMobile();
  }

  constructor(
    public translate: TranslateService,
    private loginService: LoginService,
    private checkDeviceService: CheckDeviceService,
    private router: Router,
    private animalService: AnimalService,
  ) { }

  ngOnInit(): void {}

  changeLanguage(event: Event, language: string): void {
    event.stopPropagation();
    this.animalService.onChangeLanguage();
    this.translate.use(language);
    this.showLanguageDropdown = false;
  }

  logout(): void {
    this.loginService.logout()
    .subscribe();
  }

  toggleLang(): void {
    this.translate.currentLang === 'en' ? this.translate.use('de') : this.translate.use('en');
  }

  onShowLanguageDropdown(event: Event): void {
    if (!this.isMobile) {
      return;
    }

    event.stopPropagation();
    this.showLanguageDropdown = !this.showLanguageDropdown;
  }

  onOutsideClick() {
    this.showLanguageDropdown = false;
  }

  navigateTo(link: string): void {
    this.router.navigate([link]);
  }
}
