import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private _language: BehaviorSubject<string> = new BehaviorSubject<string>('de');

  constructor() {}

  get language(): Observable<string> {
    return this._language.asObservable();
  }

  setLanguage(language: string): void {
    this._language.next(language);
  }
}
